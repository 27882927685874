<template>
    <OColumnGroup :groupId="column.groupId" :key="column.groupId" :parentGroupId="parentId" :headerName="column.groupId"  :children="column.children">
        <template v-for="col in childrenToSend">
            <TableLayoutColumnGroup v-if="col.groupId" :column="col" :parentId="column.groupId" />
            <OColumn v-else :colId="col.field" :format="col.format"
                :headerName="col.headerName ? col.headerName : col.field" :summaryAggregate="col.aggregateType"/>
        </template>
    </OColumnGroup>
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeMount } from "vue";

const props = defineProps({
    column: {
        type: Object,
        required: true,
        default: []
    },
    parentId: {
        default: null
    }
})
const childrenToSend = ref(null)

onBeforeMount(() => {
    childrenToSend.value = props.column.children

})
</script>